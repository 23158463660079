import { z } from "zod";

export type Tag = z.infer<typeof tagSchema>;
export const tagSchema = z.enum([
    "demo",
    "unreleased",
    "voicenote",
    "session",
    "videoMessage",
    "musicVideo",
    "behindTheScenes",
    "live",
    "tourDiary",
    "photo",
    "post",
    "poll",
    "ticketPresale",
    "announcement",
]);

export const TAG_NAMES = {
    "demo": "Demo",
    "unreleased": "Unreleased",
    "voicenote": "Voice Note",
    "session": "Session",
    "videoMessage": "Video Message",
    "musicVideo": "Music Video",
    "behindTheScenes": "Behind The Scenes",
    "live": "Live",
    "tourDiary": "Tour Diary",
    "photo": "Photo",
    "post": "Post",
    "poll": "Poll",
    "ticketPresale": "Ticket Presale",
    "announcement": "Announcement",
} as const satisfies Record<Tag, string>;

export const contentTypeSchema = z.enum(["audio", "video", "photo", "post", "collection"]);
export type ContentType = z.infer<typeof contentTypeSchema>;
